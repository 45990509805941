import { useCallback, useEffect, useMemo, useState } from "react";

import { isEqual, debounce, uniq } from "lodash";

import { AlarmDatasource } from "components/dashboard/widgets/alarm/settings/types";

import { useAlarmSearchInfinite } from "fetch/alarm";

import { SEEN_ALARM_STATUSES } from "utils/constants";

import { AlarmStatusType, ResultType, SearchType } from "globalTypes";

import { useAlarmWebsocket } from "./useAlarmWebsocket";
import { DashboardState } from "./useDashboardStateParam";
import { useGetLastDashboardView } from "./useGetLastDashboardView";
import useUserPreferencesStore from "./useUserPreferences";

export const useAlarms = (widgetId?: string, dataSource?: AlarmDatasource) => {
  const [showSeen, setShowSeen] = useState(
    dataSource?.statuses?.some((status) =>
      SEEN_ALARM_STATUSES.includes(status),
    ) ?? false,
  );

  const projectId = useUserPreferencesStore(
    (state) => state.project?.identifier.id,
  );

  const { lastDashboardView } = useGetLastDashboardView();

  const getEntityFilter = (
    widgetId: string | undefined,
    lastDashboardView: DashboardState | undefined,
    dataSource: AlarmDatasource | undefined,
  ) => {
    if (!widgetId) return [];

    if (lastDashboardView?.entityId) {
      return [lastDashboardView.entityId];
    }

    return dataSource?.entities.map((entity) => entity.value.id) ?? [];
  };

  const { data: alarmPages, ...query } = useAlarmSearchInfinite({
    variables: {
      queryParams: {
        projects: dataSource?.projects.map((project) => project.value) ?? [
          projectId ?? "",
        ],
        entities: getEntityFilter(widgetId, lastDashboardView, dataSource),
        entityTypes:
          uniq(dataSource?.entities.map((entity) => entity.value.type)) ?? [],
        severities: dataSource?.severities,
        type: dataSource?.type,
        statuses: dataSource?.statuses,
        startedFrom: dataSource?.startTimeFilter?.from,
        startedTo: dataSource?.startTimeFilter?.to,
        endedFrom: dataSource?.endTimeFilter?.from,
        endedTo: dataSource?.endTimeFilter?.to,
        acknowledgedFrom: dataSource?.acknowledgedTimeFilter?.from,
        acknowledgedTo: dataSource?.acknowledgedTimeFilter?.to,
        clearedFrom: dataSource?.clearedTimeFilter?.from,
        clearedTo: dataSource?.clearedTimeFilter?.to,
        searchType: SearchType.STANDARD,
        resultType: ResultType.COMPLETE,
        entitiesPerPage: 10,
      },
    },
    enabled: !!projectId,
  });

  const { data: unacknowledgedQueriesData, ...unacknowledgedQuery } =
    useAlarmSearchInfinite({
      variables: {
        queryParams: {
          projects: [projectId ?? ""],
          searchType: SearchType.STANDARD,
          resultType: ResultType.COMPLETE,
          statuses: [AlarmStatusType.ACTIVE_UNACKNOWLEDGED],
        },
      },
      enabled: !!projectId,
    });

  const {
    data: realTimeAlarms,
    previous: prevRealTimeAlarms,
    isLoading,
    toggleAlarmCleared,
  } = useAlarmWebsocket(widgetId, showSeen, dataSource);

  const debouncedRefetch = useCallback(
    () =>
      debounce(() => {
        query.refetch();
      }, 300),
    [query],
  );

  useEffect(() => {
    if (
      !isLoading &&
      alarmPages &&
      realTimeAlarms &&
      !isEqual(realTimeAlarms, prevRealTimeAlarms)
    ) {
      debouncedRefetch();
    }
    // eslint-disable-next-line
  }, [prevRealTimeAlarms, isLoading, projectId, query, debouncedRefetch]);

  useEffect(() => {
    if (dataSource) {
      setShowSeen(
        SEEN_ALARM_STATUSES.includes(
          dataSource?.statuses?.[0] ?? AlarmStatusType.ACTIVE_ACKNOWLEDGED,
        ),
      );
      query.refetch();
    }
    // eslint-disable-next-line
  }, [dataSource]);

  const alarms = useMemo(() => {
    if (!alarmPages) return [];
    return alarmPages.pages.flatMap((page) => page.payload);
  }, [alarmPages]);

  const newAlarmCount = useMemo(() => {
    return unacknowledgedQueriesData?.pages?.[0]?.numberOfRecords ?? 0;
  }, [unacknowledgedQueriesData]);

  return {
    showSeen,
    setShowSeen,
    alarms,
    toggleAlarmCleared,
    newAlarmCount,
    query,
    unacknowledgedQuery,
  };
};
